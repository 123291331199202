import React from 'react'

export default function ClassAttire() {
    return (
        <div className="class-attire-container">
            <h2>Class Attire</h2>
            <main>
                <section className="section-color">
                    <h3>West Haven & Guilford</h3>
                    <div className="girls-boys">
                        <div>
                            <h4>All Dancers</h4>
                            <ul>
                                <li>Warm Weather: Shorts and Irish Dance Shirt</li>
                                <li>Cooler Weather: Sweatpants, Leggings (shorts are still acceptable), and Irish Dance Shirt</li>
                                {/* Bold No */}
                                <li>Absolutely NO jeans, baggy pants, or sweatshirts that hide your dancer’s hands</li>
                            </ul>
                        </div>
                        <div className='boys'>
                            <h4>Girls</h4>
                            <ul>
                                {/* Bold No */}
                                <li>Soft Shoe Ghillies (beginners can wear black ballet shoes)</li>
                                <li>Hair pulled away from face in ponytail, braid or bun</li>
                                <li>Socks and shoes – both ghillies and hard shoes if needed</li>
                            </ul>
                        </div>
                        <div className="boys">
                            <h4>Boys</h4>
                            <ul>
                                <li>Any Colored T-Shirt With Athletic Shorts or Pants</li>
                                <li>Jazz Shoes</li>
                            </ul>
                        </div>
                    </div>
                    <p>Hard Shoe Level: Get Flexi Hard Shoes <a href={'https://www.danceirish.com'}>here</a> USE 'Mulkerin' for 10% off</p>
                    <p>Please contact Miss Kathy directly for any additional questions on appropriate attire</p>
                </section>
                <section>
                    <h3>Killingworth Classes: The Dance Corner Dress Code</h3>
                    <div className="girls-boys">
                        <div>
                            <h4>Girls</h4>
                            <ul>
                                <li>Any colored leotard with tights. Optional black fitted dance shorts, black jazz pants or chiffon ballet skirt</li>
                                <li>Black leather ballet shoes for Beginner/Int. 1</li>
                                <li>Black ghillies for Int. 2 and advanced classes – hard shoes if needed</li>
                                <li>Hair pulled away from face in ponytail, braid or bun</li>
                            </ul>
                        </div>
                            <div className="boys">                      
                                <h4>Boys</h4>
                                <ul>
                                    <li>Any Colored T-Shirt With Athletic Pants</li>
                                    <li>Black jazz shoes with heel</li>
                                </ul>
                            </div>  
                        </div>
                    <p>Please contact Miss Elizabeth directly for any additional questions on appropriate attire</p>
                </section>
                <section className="section-color">
                    <h3>Norwalk Classes</h3>
                    <p>Norwalk instructors will let you know the attire for their classes at the start of class.</p>
                </section>
                {/* <section className="section-color">
                    <h3>Westbrook Classes: Westbrook Dance Academy Dress Code</h3>
                    <div className="girls-boys">
                        <div>
                        <h4>Girls</h4>
                            <ul>
                                <li>Any style solid black leotard, tan or black tights. Warmups may be worn at the discretion of the instructor; black skirt/black fitted shorts may be worn.</li>
                                <li>OR: if no other WDA class: t-shirt &amp; shorts, leggings &amp; t-shirt are appropriate</li>
                                <li>Hair pulled away from face in ponytail, braid, or bun</li>
                                <li>Socks and shoes – both ghillies and hard shoes if needed</li>
                            </ul>
                        </div>
                        <div className="boys">
                            <h4>Boys</h4>
                            <ul>
                                <li>Any Colored T-Shirt With Athletic Shorts or Pants</li>
                                <li>Black jazz shoe with heel</li>
                            </ul>
                        </div>
                    </div>
                    <p>Please contact Miss Casey directly for any additional questions on appropriate attire</p>
                </section> */}
            </main>
        </div>
    )
}
