import React from 'react'

// IMAGES
import TDC from '../../images/TheDanceCorner.png'
import WDA from '../../images/WDA.png'


export default function ContactUs() {
    return (
        <div className="contactus-container">
            <div>
                <h2>Contact Us</h2>
            </div>
            <main>
                <div className="contact">
                    <div>
                        <h5 style={{ fontWeight: 'bold', fontSize: '2rem'}}>Contact Owner/Director</h5>
                        <p style={{ fontSize: '1.5rem', fontWeight: 'bold'}}>Kathy Carew <br/>TCRG and ADCRG</p> 
                        <a href={'mailto: mulkerinirishdancers@yahoo.com'}><p style={{ fontSize: '1rem'}}><em className="titles">Email</em>: mulkerinirishdancers@yahoo.com</p></a>
                        <p style={{ fontSize: '1rem'}}>Contact Kathy for more information on classes or to schedule your free class. Also contact Kathy to schedule a performance.</p>
                        <p style={{ fontSize: '1rem'}}>Home Base: 263 Center St, West Haven, CT </p>
                        <p style={{ fontSize: '1rem'}}>Phone Number: (203)934-2267</p>
                    </div>
                    {/* <div className="owner"> */}
                    <div>
                        {/* <h5 style={{ fontWeight: 'bold'}}>Contact Owner/Director</h5>
                        <p style={{ fontSize: '1.25rem', fontWeight: 'bold'}}>Kathy Carew <br/>TCRG and ADCRG</p>  */}
                    <div className="info-div" style={{ borderTop: "1px solid black", width: '100%', paddingTop: '3%'}}>
                        {/* Insert Email */}
                            <h5 style={{ fontWeight: 'bold', fontSize: '1.5rem'}}>The Dance Corner</h5>
                            <p style={{ fontSize: '1rem'}}>Elizabeth Florentine <br/>Lead Teacher</p>
                            <p style={{ fontSize: '1rem'}}><em className="titles">Phone</em>: (860) 663-3738</p>
                            <a href={"mailto: misselizabethdc@gmail.com"}><p style={{ fontSize: '1rem'}}><em className="titles" >Email</em>: misselizabethdc@gmail.com</p></a>
                        </div>
                        <p style={{ fontSize: '1rem'}}>Adult Classes to be held in West Haven and Milford</p>

                        {/* <div style={{ paddingTop: '3%'}}>
                            <h5 style={{ fontWeight: 'bold' }}>Guilford Classes</h5>
                            <a href={'https://guilfordparkrec.com'}><p>Guilford Park Rec</p></a>
                            <p style={{ fontSize: '1rem'}}>Adult Classes to be held in West Haven and Milford</p>
                        </div> */}
                    </div>
                </div>
                <div className="contact-instructors">
                    {/* <div className="mobile-contact">
                        <h4>Contact</h4>
                        <p>If you have any questions or comments regarding our class schedule, performances, camps, or public appearances, please feel free to email us at mulkerinirishdancers@yahoo.com. </p>
                    </div> */}
                    <h4 style={{ fontWeight: 'bold', fontSize: '2rem' }}>Contact Our Other Instructors</h4>
                    <div style={{ padding: '3% 0', width: '80%'}}>
                        <h5 style={{ fontWeight: 'bold' }}>Norwalk Classes</h5>
                        <p>Contact Kathy </p> 
                        <a href={'mailto: mulkerinirishdancers@yahoo.com'}><p><em className="titles">Email</em>: mulkerinirishdancers@yahoo.com</p></a>
                        {/* <p className="titles">Main Location:</p> */}
                        {/* <address> 
                            <p>West Haven, CT</p>
                        </address> */}
                    </div>
                    
                    <div className="info-div">
                        <h5 style={{ fontWeight: 'bold' }}>Guilford Classes</h5>
                        <a href={'https://guilfordparkrec.com'}><p>Guilford Park Rec</p></a>
                        <p>Contact Kathy </p> 
                        <p style={{ fontSize: '1rem'}}>Phone Number: (203)453-8068</p>
                        <a href={'mailto: mulkerinirishdancers@yahoo.com'}><p><em className="titles">Email</em>: mulkerinirishdancers@yahoo.com</p></a>
                    {/* Insert Email */}
                            {/* <h5 style={{ fontWeight: 'bold'}}>The Dance Corner</h5>
                            <p>Elizabeth Florentine <br/>Lead Teacher</p>
                            <p><em className="titles">Phone</em>: (860) 663-3738</p>
                            <a href={"mailto: misselizabethdc@gmail.com"}><p><em className="titles">Email</em>: misselizabethdc@gmail.com</p></a> */}
                    </div>
                </div>
            </main>
        </div>
    )
}
