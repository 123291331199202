import React from 'react';
import { Route } from "react-router-dom";
// COMPONENTS
import Header from './components/Header';
import Hamburger from './components/Hamburger';
import LandingPage from './components/LandingPage';
import Footer from './components/Footer';
// ABOUT US
import AboutUs from './components/aboutUs/AboutUs';
import Herself from './components/aboutUs/Herself';
import MissElizabeth from './components/aboutUs/MissElizabeth';
import MissKathy from './components/aboutUs/MissKathy';
// CLASSES
import ClassAttire from './components/classes/ClassAttire';
import Classes from './components/classes/Classes';
import SummerCamps from './components/classes/SummerCamps';
// CONTACT US
import ContactUs from './components/contactus/ContactUs';
// FEIS
import Feis from './components/feis/Feis';
import UpcomingFeis from './components/feis/UpcomingFeis';
// IRELAND
import IrelandTeam from './components/ireland/IrelandTeam';

function App() {


  return (
    <div className="App">
      <Header/>
      <Hamburger className="tablet-mobile" />
      <Route exact path="/" component={LandingPage}/>
      <Route exact path="/aboutus" component={AboutUs}/>
      <Route exact path="/herself" component={Herself}/>
      <Route exact path="/misskathy" component={MissKathy}/>
      <Route exact path="/misselizabeth" component={MissElizabeth}/>
      <Route exact path="/classes" component={Classes}/>
      <Route exact path="/classattire" component={ClassAttire}/>
      <Route exact path="/summercamp" component={SummerCamps}/>
      <Route exact path="/contact" component={ContactUs}/>
      {/* <Route exact path="/feis" component={Feis}/> */}
      <Route exact path="/upcomingfeis" component={UpcomingFeis}/>
      <Route exact path="/irelandteam" component={IrelandTeam}/>
      <Footer/>
    </div>
  );
}

export default App;
