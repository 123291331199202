import React from 'react'
import Kathy from '../../images/Misskathy.png'

export default function MissKathy() {
    return (
        <div className="kathy-container">
            <section>
                <h2>Meet Miss Kathy</h2>
            </section>
            <div className="tab-mobile">
                <img src={Kathy} alt="Miss Kathy"/>
                <h3>Kathy Carew</h3>
                <h4>TCRG and ADCRG</h4>
            </div>
            <main className="kathy-main main-img">
                <div className="kathy-img">
                    <img src={Kathy} alt="Miss Kathy"/>
                    <h3>Kathy Carew, <br/> TCRG and ADCRG</h3>
                </div>
                <section>
                    <h3>Owner and Lead Teacher</h3>
                    <p>Kathy lived most of her young life in Stamford, CT. She started Irish dancing with the Kathleen Mulkerin School of Irish dance at the age of 5. At 7 she was the youngest performer on the first of her many tours to Ireland with the well-known Emerald Dancers under the direction of Kathleen Mulkerin.</p>
                    <p>At age 8 Kathy acted and sang in Summer stock in Chataqua New York, where she played the part of  the youngest Von Trapp Child in the <em style={{ fontStyle:"italic"}}>Sound of Music</em>. Kathy spent much of her time from then on performing in musicals, dance shows, commercials and movies.</p>
                    <p>Kathy has performed on stage and television in the United States, Canada and in Ireland.  While in college, Kathy performed with The Emerald Dancers at Carnagie Hall. Kathy had also competed in and won many awards throughout the United States, Ireland and Canada for her solo and group dancing.</p>
                    <p>Over the years Kathy also took classes in Ballet, Jazz and modern dance, acting and singing, but Irish dance always seemed to be in  her blood. Kathy attended Southern Sem and Longwood Colleges in Virginia, where she majored in Education. After college Kathy worked part time as an Irish dance teacher and full time as the director of a daycare center in New Haven. Kathy continued teaching both in the fields of dancing and computer education until 2000, when she decided to focus completely on Irish Dancing.</p>
                    <p>Kathy teaches classes in Norwalk, Milford, Shelton, West Haven, and Guilford. The school also has classes in Killingworth and Westbrook.</p>
                    <p>In 2016, Kathy took a group of 16 of her most advanced dancers on a 2 week trip to Ireland. There they performed in shows throughout Ireland, as well as being able to learn more about the Irish people, their culture, music, and dance. This is the 8th trip to Ireland our dance group has made since 1963.</p>
                    <p>Kathy says her goal is to "teach the dancers a love of Irish dancing, it's music and culture."</p>
                    <p>Her belief is that life is short, enjoy what you do, and let dance be a part of every day.</p>
                </section>
            </main>
        </div>
    )
}
