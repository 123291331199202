import React from 'react'
import FeisPic from '../../images/RTN.jpg'

export default function UpcomingFeis() {
    return (
        <section className="feis-event-list">
            {/* PIC OF RTN WITH LINK */}
            <h2>Upcoming Feisanna with RTN</h2>
            <div className="feis-logo">
                <a href="https://www.rtnirishdance.com/events"><img src={FeisPic} alt="Feis Logo"/></a>
                <p>Click the Image for Registration Information</p>
            </div>
            <main>
                {/* <div>
                    <h4>Connemara Harvest Feis: Dallas, PA</h4>
                    <ul>
                        <li>Saturday, October 9th, 2021</li>
                    </ul>
                    <h4>Lee Autumn Harvest Feis: North Grosvenordale, CT</h4>
                    <ul>
                        <li>Saturday, October 16th, 2021</li>
                    </ul>
                    <h4>Ridgewood Blackout Feis: Northern NJ TBD</h4>
                    <ul>
                        <li>Saturday, October 23rd, 2021</li>
                    </ul>
                    <h4>New Endgland Championships: Fitchburg, MA</h4>
                    <ul>
                        <li>November 20th, 2021</li>
                    </ul>
                    <h4>Jennifer Lynn Feis: Northern NJ Area</h4>
                    <ul>
                        <li>Saturday, January 22nd, 2022</li>
                    </ul>
                </div> */}
                <div>
                    <h4>Fall In Love With Irish Dance at the Daly School Feis: Atlantic Highlands, NJ area</h4>
                    <ul>
                        <li>February 12th, 2022</li>
                    </ul>
                    <h4>Flying Irish Spring Feis: Fitchburg, MA</h4>
                    <ul>
                        <li>Saturday, April 2nd, 2022</li>
                    </ul>
                    <h4>Lee Irish Dance Feis: TBD</h4>
                    <ul>
                        <li>Saturday, April 30th, 2022</li>
                    </ul>
                    <h4>Mid-Atlantic Championships</h4>
                    <ul>
                        <li>May 7th and May 8th</li>
                    </ul>
                    <h4>Pride of Erin Feis</h4>
                    <ul>
                        <li>May 2022</li>
                    </ul>
                    <h4>McHale School of Irish Dance Feis</h4>
                    <ul>
                        <li>Saturday, May 21st, 2022</li>
                    </ul>
                    <h4>Peters School of Irish Dance Feis: TBD</h4>
                    <ul>
                        <li>July 2022</li>
                    </ul>
                    <h4>RTN 2022 Northern American Nationals</h4>
                    <ul>
                        <li>July/August 2022</li>
                    </ul>
                </div>
            </main>
        </section>
    )
}
