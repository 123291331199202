import React from 'react';
import MulkSmall from '../images/mulkSmall.png';
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons'


const Footer = () => {
    return (
        <div className="footer">
            <footer>
                <div>
                    <p>Follow Us On Facebook</p>
                    <a href="https://www.facebook.com/irishdanzing/"><FacebookOutlined className="facebook" style={{ fontSize: '40px', color: 'white'}}/></a>
                    <a href="https://www.instagram.com/mulkerinschool/"><InstagramOutlined className="facebook" style={{ fontSize: '40px', color: 'white'}}/></a>
                </div>
                <img alt="Mulkerin-Logo" src={MulkSmall}/>
                <p className="aj-email">Website Created by  <br/>Anthony Hill</p>
            </footer>
        </div>
    )
}



export default Footer;