import React from 'react';
import Camp from '../../images/Camp2.jpg'
import SquareClasses from '../../images/SquareClasses.jpg';
import DanceCamp from '../../images/MulkerinDanceCamp.jpg';
import SummerCamp from '../../images/newsummer.jpg';


export default function SummerCamps() {
    return (
        <div className="summercamp-container">
            <div className="summer-title-bg">
                <h2>Summer Irish Dance Camp on the Water</h2>
            </div>
            <main className="camp-info">
                <section className="summercamp-intro">
                    <img src={SquareClasses} alt="Shamrock Circle"/>
                    <div> 
                        <h3 style={{ textAlign: 'center' }}>Come Join Us!</h3>
                        <p>Tiny Dancer, Beginner and Intermediate Camp includes crafts and snacks.</p>
                        <p>Advanced Camps include snack and intense work on technique and new steps.</p>
                        <p><span style={{ fontWeight: 'bold' }}>All Camps have a little Beachcombing</span></p>
                        <p><span style={{ fontWeight: 'bold' }}>All Camp Sessions</span> will be held outside, masked, and distanced</p>
                        <p>We will also have distance mask breaks.</p>
                    </div> 
                    <img src={DanceCamp} alt="Children At Camp"/>
                </section>
                <h2>Camp Schedule</h2>
                <div className="camp-registration">
                    <h4>Registration For Summer Camp Now Open</h4>
                    <a href="https://ktmulkerin.wufoo.com/forms/z1m9hgal0ayp3xn/"><button>REGISTER HERE</button></a>
                </div>
                <section className="summercamp-sessions" style={{ justifyContent: 'center'}}>
                    <img 
                        src={SummerCamp} 
                        alt="Mulkerin Dance Camps Schedule"
                        style={{ display: 'flex', width: '70%'}}
                    />
                </section>
                {/* <section className="summercamp-sessions">

                    <div className="camp-schedule">
                        <h4>*Sessions will be held from 9am - 12pm*</h4>
                        <h3>Session 1</h3>
                        <p> <span style={{ fontWeight: 'bold' }}>July 5th – July 10th:</span> <br/>Rinceoiri Bideacha (tiny dancers) ages 4 and up try Irsh Dance and Beginner Dancers</p>
                        <h3>Session 2</h3>
                        <p><span style={{ fontWeight: 'bold' }}>July 12th – July 16th:</span> <br/> Rinceoiri Idirmheanach (Intermediate) Starting Hard Shoe Dancing</p>
                        <h3>Session 3</h3>
                        <p><span style={{ fontWeight: 'bold' }}>July 19th- July 23th:</span> <br/> Rinceoiri Roimh re aon (Advanced Dancers 1) Treble Jib, Treble Hornpipe, Traditional Set Pieces</p>
                        <h3>Session 4</h3>
                        <p><span style={{ fontWeight: 'bold' }}>August 2nd – August 6th:</span> <br/>Rinceoiri Roimh re do (Advance Dancers 2) Non-Traditional Set Pieces and More Advanced Steps</p>
                        <h3>Session 5</h3>
                        <p>Nationals Camp in August TBA</p>
                    </div>
                    <img src={Camp} alt="Camp In the Studio"/>
                </section> */}
            </main>
        </div>
    )
}
