import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';

const Header = () => {
    return(
        <main className="header"> 
            <section className="header-align">
               <div>
                    <Link to="/"><h1>Mulkerin School of Irish Dance</h1></Link>
                    <h4>Passing on the love of Irish dance, it’s music and culture</h4>
                </div>
            </section>
            <nav className="navBar">
                <ul>
                   <Link to="/" style={{ textDecoration: 'none', color: 'green'}}><li className="nav-title"><p>Home</p></li></Link>
                    <li className="nav-title"><Link to="/aboutus" style={{ textDecoration: 'none'}}><li><p>About Us <CaretDownOutlined /></p></li></Link>
                        <ul className="dropdown">
                            <Link to="/herself" style={{ textDecoration: 'none'}}><li><p>Herself</p></li></Link>    
                            <Link to="/misskathy" style={{ textDecoration: 'none'}}><li><p>Miss Kathy</p></li></Link>    
                            <Link to="/misselizabeth" style={{ textDecoration: 'none'}}><li><p>Miss Elizabeth</p></li></Link>       
                        </ul>
                    </li>
                    <li className="nav-title"><Link to="/classes" style={{ textDecoration: 'none'}}><li><p>Classes <CaretDownOutlined /></p></li></Link>
                        <ul className="dropdown">
                            <Link to="/summercamp" style={{ textDecoration: 'none'}}><li><p>Summer Camp</p></li></Link>
                            <Link to="/classattire" style={{ textDecoration: 'none'}}><li><p>Class Attire</p></li></Link>
                        </ul>
                    </li>
                    {/* <li className="nav-title"><Link to="/feis" style={{ textDecoration: 'none'}}><li><p>Feis <CaretDownOutlined /></p></li></Link>
                        <ul className="dropdown">
                            <Link to="/upcomingfeis" style={{ textDecoration: 'none'}}><li><p>Upcoming Feis</p></li></Link>
                        </ul>
                    </li> */}
                    <li className="nav-title"><Link to="/irelandteam" style={{ textDecoration: 'none'}}><li><p>Emerald Dancers</p></li></Link>
                    </li>
                    <li className="nav-title"><Link to="/contact" style={{ textDecoration: 'none'}}><li><p>Contact Us</p></li></Link></li>
                </ul>
            </nav>
        </main>
    )
}

export default Header;