import React from 'react'
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons'
import TeamStore from './TeamStore.js';
// import Dancers from '../../images/Store-Emerald-Team.jpg';
import Dancers from '../../images/beach-green.jpg';
import MulkerinTour from './MulkerinTour.pdf';

export default function IrelandTeam() {
    return (
        <div className="irelandteam-container">
            <h2>Emerald Irish Dancers</h2>
            <main>
                <h3>For More Information On Our <a href={MulkerinTour}>Ireland Tour</a></h3>
                <p>Nearly every 5 years since 1963, the “Emerald Dancers” have been traveling to Ireland for a
    performance tour. A group of 16 will tour Ireland for two weeks during the summer of 2025. The
    dancers will perform for Lord and Lady Mayors, in castles and of course, the occasional pub.</p>
            </main>
            <img style={{ width: `${'70%'}` }}src={Dancers} alt="Ireland Team"/>
            <div style={{ paddingTop: '3%'}}>
                <p style={{ fontSize: '1.2rem'}}>Check Out Our Emerald Dancers on Facebook and Instagram</p>
                <div style={{ display: 'flex', flexDirection: 'row', width: '20%', justifyContent: 'space-between'}}>
                    <a href="https://www.facebook.com/Mulkerin-Emerald-Dancers-349948845640792/"><FacebookOutlined style={{ fontSize: '40px', color: "@header-font-color"}}/></a>
                    <a href="https://www.instagram.com/mulkerinschool/"><InstagramOutlined style={{ fontSize: '40px', color: "@header-font-color"}}/></a>
                </div>
            </div>
            {/* <TeamStore/> */}
        </div>
    )
}
