import React from 'react'
// import Data from './aboutus.json';
// Pictures
import MulkLogo from '../../images/MulkerinLogo.png';
import RTN from '../../images/RTN.jpg';

export default function AboutUs() {
    return (
        <div className="aboutus-container">
            <div>
                <h2>Who We Are</h2>
            </div>
            <div className="camp-registration mobile-tab-reg">
                 {/* <section className="new-camp">
                     <h4>New 2022 Summer Camp Schedule and Registry!</h4>
                     <Link to="/summercamp"><button>Camp Schedule</button></Link>
                </section> */}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '2%' }}>
                    <h4>A Member of</h4>
                    <img src={RTN} alt="RTN Logo"/>
                </div>
                {/* <h4>Class Registration</h4>
                <a href="https://ktmulkerin.wufoo.com/forms/m125nth1dccsjw/"><button>REGISTER HERE</button></a> */}
            </div>
            <div className="aboutus-main-and-pics">
                <main className="aboutus-main">
                    <p>The Mulkerin School of Irish dance was started by Kathleen Mulkerin in 1940 and is recognized as one of Connecticut’s first Irish dancing schools.  We are dedicated to passing on the love of Irish dance, it’s music and culture to our students.  This was Kathleen’s philosophy and one we continue to pass on to each generation.</p>
                    <p>We pride ourselves in teaching our dancers confidence, teamwork, and the importance of helping others. We encourage our dancers to perform their very best and pass on the happiness it brings them to our viewers.  You’ll find our dancers performing at local nursing homes, the Special Olympics, Irish festivals, schools, and for famous Irish musicians.</p>
                    <img alt="Mulkerin-Logo" src={MulkLogo} className="about-tab-img"/>
                    <p>Aside from our many performances, Mulkerin dancers have the opportunity to participate in ‘Feiseanna’ (Irish dance competition) with the open platform organization; Rince Tuatha Nua.  Many of our former and current students have regional and national titles, qualified for All-Ireland’s, and even the World Championship!</p>
                    <p>By joining the Mulkerin school, you’ll become a part of Kathleen’s legacy by passing on a part of Irish culture from one generation to the next.</p>
                </main>
                <section className="aboutus-pics">
                    <img alt="Mulkerin-Logo" src={MulkLogo}/>
                </section>
            </div>
            <p className="aboutus-bottom">Come join the legacy! </p>
        </div>
    )
}
