import React from 'react'
import { Link } from 'react-router-dom';
import arrowRight from '../images/arrow-right.png';


export default function Hamburger() {

    return (
        <div className="sidebar">
            <input type="checkbox" id="menu"/>
            <label for="menu" id="nav-icon" >&#9776;</label>
            <div className="multi-level">
                <div className="item">
                    <input type="checkbox" id="home_"/>
                    <label for="home"><Link to="/" style={{ textDecoration: 'none', color: 'rgb(3, 97, 3)'}}>Home</Link></label>
                </div>
                <div className="item">
                    <input type="checkbox" id="about_us"/>
                    <img src={arrowRight} className="arrow" alt="right arrow"/>
                    <label for="about_us"><Link to="/aboutus" style={{ textDecoration: 'none', color: 'rgb(3, 97, 3)'}}>About Us</Link></label>
                    <ul>
                        <Link to="/herself"><li><p>Herself</p></li></Link>    
                        <Link to="/misskathy" style={{ textDecoration: 'none'}}><li><p >Miss Kathy</p></li></Link>    
                        {/* <Link to="/misscasey" style={{ textDecoration: 'none'}}><li><p >Miss Casey</p></li></Link>     */}
                        <Link to="/misselizabeth" style={{ textDecoration: 'none'}}><li><p >Miss Elizabeth</p></li></Link>    
                    </ul>
                </div>
                <div className="item">
                    <input type="checkbox" id="classes_"/>
                    <img src={arrowRight} className="arrow" alt="right arrow"/>
                    <label for="classes_"><Link to="/classes" style={{ textDecoration: 'none', color: 'rgb(3, 97, 3)'}}>Classes</Link></label>
                    <ul>
                        <Link to="/summercamp" style={{ textDecoration: 'none'}}><li><p>Summer Camp</p></li></Link>
                        <Link to="/classattire" style={{ textDecoration: 'none'}}><li><p>Class Attire</p></li></Link>
                    </ul>
                </div>
                {/* <div className="item">
                    <input type="checkbox" id="feis_"/>
                    <img src={arrowRight} className="arrow" alt="right arrow"/>
                    <label for="feis_"><Link to="/feis" style={{ textDecoration: 'none', color: 'rgb(3, 97, 3)'}}>Feis</Link></label>

                    <ul>
                        <Link to="/feis" style={{ textDecoration: 'none'}}><li><p>Feis</p></li></Link>
                        <Link to="/upcomingfeis" style={{ textDecoration: 'none'}}><li><p>Upcoming Feis</p></li></Link>
                    </ul>
                </div> */}
                <div className="item">
                    <input type="checkbox" id="ireland_"/>
                    <label for="ireland_"><Link to="/irelandteam" style={{ textDecoration: 'none', color: 'rgb(3, 97, 3)'}}>Ireland Team</Link></label>
                </div>
                <div className="item">
                    <input type="checkbox" id="contact_"/>
                    <label for="contact_"><Link to="/contact" style={{ textDecoration: 'none', color: 'rgb(3, 97, 3)'}}>Contact Us</Link></label>
                </div>

            </div>
        </div>
    )
}

