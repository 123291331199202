import React from 'react'
// import Irleland3 from "../../images/ireland3.png"
import Irleland3 from "../../images/maddie.JPG";
import Irleland4 from "../../images/stretch-circle.png";
import classSchedule from "../../images/classSchedule.jpg";



export default function Classes() {
    return (
        <div className="classes-container">
            <div className="classes-title">
                <h2>Classes</h2>
            </div>
                <h4><a href="https://ktmulkerin.wufoo.com/forms/m125nth1dccsjw/">New Student Registration</a></h4>
                <p>Please Contact Teacher for Your Area Before Registering</p>
            <main>
                <section>
                    <p>Our classes are offered for both boys and girls ages 4 and up and consist of both step and Ceili dances.
        Our beginner class is an introduction to Irish dance technique focusing on posture, pointed toes, hops,
        skips, balance and musicality. As a beginner, your dancer will learn the basic steps of the Jig and may
        progress into the Reel.</p>
                    <p>Our intermediate and advanced intermediate classes will continue to focus on technique as well as
        perfecting the basic movements learned as a beginner. Your dancer will continue to learn more
        advanced steps of the Jig, Reel and will progress onto Slip Jig. Once your dancer has become proficient in
        the light shoe dances, hard shoe dances such as the Hornpipe and Treble Jig will be introduced. It will be
        your teacher’s discretion when hard shoes can be purchased for you dancer.</p>
                    <img src={Irleland3} alt="Dance Competition" className="tab-img"/>
                    <p>Our advanced classes will continue to perfect technique with emphasis on posture, foot placement, and
        proper execution of steps. Our advanced dancers will continue to learn more advanced steps of the Jig,
        Reel, Slip Jig, Treble Jig, and Hornpipe. Traditional and Non-Traditional set pieces will also be introduced.
        As an advanced dancer, you can expect to learn a few steps from Riverdance and Lord of the Dance!</p>
                </section>
                <img src={Irleland3} alt="Dance Competition"/>
                {/* <img src={Irleland4} alt="Dancers Stretch Circle"/> */}
            </main>
            <div className="feis-class-registration">
                <h4>Class Payments</h4>
                <p>We accept Venmo:</p><span>@Kathy-Carew-1</span>
                <p>We accept PayPal:</p><span>foreverkjc@aol.com</span>
            </div>
            <section className="table-section">
                <div>
                    <h3>Class Schedule</h3>
                </div>
                <img src={classSchedule} alt="class-schedule" className="feis-class-registration"/>
                {/* <table>
                    <tr className="t-header">
                        <th>Day</th>
                        <th className="t-location">Location</th> 
                        <th className="t-time">Time</th>
                        <th>Level</th>
                    </tr> */}
    {/* START
    {/* KILLINGWORTH --- MONDAY --- 4:30-5:30PM ALL LEVELS */}
                    {/* <tr className="t-rows t-top">
                        <td className="t-days">Monday</td>
                        <td className="t-mon color">The Dance Corner: <br/> Killingworth, CT</td>
                        <td className="t-data color">4:35pm - 5:30pm</td>
                        <td className="t-data color">Advanced</td>
                    </tr>  */}
    {/* KILLINGWORTH --- MONDAY --- 7:00PM ADULT CLASS */}
                    {/* <tr className="t-rows test">
                        <td className="nothing "></td>
                        <td className="nothing color"></td>
                        <td className="t-data color">5:35pm - 6:30pm</td>
                        <td className="t-data color">Beginner/Inter 1</td>
                    </tr> 
                    <tr className="t-rows test">
                        <td className="nothing "></td>
                        <td className="nothing color"></td>
                        <td className="t-data color">6:35pm - 7:30pm</td>
                        <td className="t-data color">Intermediate 2</td>
                    </tr>  */}
    {/* START */}
    {/* SHELTON --- TUESDAY --- 4:30-5:30PM ALL LEVELS */}
                    {/* <tr className="t-rows t-top">
                        <td className="t-days">Tuesday</td>
                        <td>St. Phillip's School <br/>Norwalk, CT</td>
                        <td className="t-data">4:30 – 6:00pm</td>
                        <td className="t-data">All Levels</td>
                    </tr> */}
    {/* START */}
    {/* SHELTON --- WEDNESDAY --- 4:30-5:30PM ALL LEVELS */}
                    {/* <tr className="t-rows t-top">
                        <td className="t-days">Wednesday</td>
                        <td className="color">
                            September - December: WH Studio 263 Center St. <br/><br/>
                            January - May: Irish Heritage Society Milford 131 Bridgeport Ave
                        </td>
                        <td className="t-data color">5:00pm - 6:30pm</td>
                        <td className="t-data color">Competition Class</td>
                    </tr> 
                    <tr className="t-rows">
                        <td className="nothing"></td>
                        <td className="color"></td>
                        <td className="t-data color">6:30pm - 7:30pm</td>
                        <td className="t-data color">Adult Class</td>
                    </tr>  */}
    {/* START */}
    {/* GUILFORD --- THURSDAY --- 3:45PM BEGINNER/INTERMEDIATE */}
                    {/* <tr className="t-rows t-top">
                        <td className="t-days">Thursday</td>
                        <td className="t-thurs">Guilford Community Center: <br/>Guilford, CT</td>
                        <td className="t-data">4:45pm</td>
                        <td className="t-data">Beginner/Intermediate</td>
                    </tr>  */}
    {/* GUILFORD --- THURSDAY --- 4:45PM ADVANCED */}
                    {/* <tr className="t-rows test">
                        <td className="nothing"></td>
                        <td className="nothing"></td>
                        <td className="t-data">3:45pm</td>
                        <td className="t-data">Advanced</td>
                    </tr>  */}
    {/* START */}
    {/* WESTBROOK --- THURSDAY --- 5:00PM Intermediate 1 */}
                    {/* <tr className="t-rows test">
                        <td className="nothing"></td>
                        <td className="t-dotted">Westbrook Dance Academy:<br/> Westbrook, CT</td>
                        <td className="t-data">4:45pm</td>
                        <td className="t-data">Intermediate</td>
                    </tr>  */}
    {/* WESTBROOK --- THURSDAY --- 6:00PM Intermediate 1 */}
                    {/* <tr className="t-rows test">
                        <td className="nothing"></td>
                        <td className="nothing"></td>
                        <td className="t-data">6:30pm</td>
                        <td className="t-data">Novice</td>
                    </tr>  */}

    {/* WESTBROOK --- THURSDAY --- 7:00PM FEIS/PRIVATE LESSONS */}
                    {/* <tr className="t-rows test">
                        <td className="nothing"></td>
                        <td className="nothing"></td>
                        <td className="t-data">7:30pm</td>
                        <td className="t-data">Advanced</td>
                    </tr>  */}
    {/* START */}
    {/* WEST HAVEN --- FRIDAY --- 4:00pm Beginner/Advanced Beginner */}                    
                    {/* <tr className="t-rows t-top">
                        <td className="t-days">Friday</td>
                        <td className="nothing color">West Haven Studio: <br/>263 Center St., West Haven, CT</td>
                        <td className="t-data color">4:30pm - 5:15pm</td>
                        <td className="t-data color">Beginner</td>
                    </tr>  */}
    {/* WEST HAVEN --- FRIDAY --- 5:00PM Intermediate                    
                    <tr className="t-rows test">
                        <td className="nothing"></td>
                        <td className="nothing color"></td>
                        <td className="t-data color">5:15pm - 6:15pm</td>
                        <td className="t-data color">Intermediate</td>
                    </tr>  */}
    {/* WEST HAVEN --- FRIDAY --- 6:00PM ADVANCED */}                    
                    {/* <tr className="t-rows test">
                        <td className="nothing"></td>
                        <td className="nothing color"></td>
                        <td className="t-data color">6:00pm - 7:00pm</td>
                        <td className="t-data color">Advanced</td>
                    </tr>  */}
    {/* START */}
    {/* WESTBROOK --- FRIDAY --- 5:15PM FEIS/PRIVATE LESSONS */}
                    {/* <tr className="t-rows test">
                        <td className="nothing"></td>
                        <td className="t-dotted">Westbrook Dance Academy: <br/>Westbrook, CT</td>
                        <td className="t-data">5:15pm</td>
                        <td className="t-data">Feis Class/Private Lessons</td>
                    </tr>  */}
    {/* START */}                                      
    {/* None --- SATURDAY */}
                    {/* <tr className="t-rows t-top">
                        <td className="t-days">Saturday</td>
                        <td className="nothing">NO CLASSES</td>
                        <td className="nothing"></td>
                        <td className="nothing"></td>
                    </tr> */}
    {/* Select Group Classes --- SUNDAY */}
                    {/* <tr className="t-rows t-top">
                        <td className="t-days">Sunday</td>
                        <td className="nothing color">Select Group Classes</td>
                        <td className="nothing color"></td>
                        <td className="nothing color"></td>
                    </tr>
                </table> */}
            </section>
        </div> 
    )
}
