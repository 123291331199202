import React from 'react';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';

import Carousel from './Carousel'
// import MyVerticallyCenteredModal from './modal';
//START Landing Page Ordered Pics
import PicClasses from '../images/SquareClasses.jpg';
import PicFeis from '../images/Square4.jpg';
import PicAboutUs from '../images/Shamrock.jpg';
import PicCamps from '../images/potentialbackground.jpg';
import RTN from '../images/RTN.jpg';
//END Landing Page Ordered Pics
import { Button } from 'antd';
import MulkerinLogo from '../images/MulkerinLogo.png';
const LandingPage = () => {
    // const [isOpen, setOpen] = useState(true)



    return(
        <section className="landing-container" >
            {/* <MyVerticallyCenteredModal show={isOpen} onHide={() => setOpen(false)}/> */}
            <div className="mobile-tab-reg">
                <p>Mulkerin School of Irish Dance has been involved in classes, shows, and competitions for 83 years. Come Join Our Legacy!</p>
            </div>
            <div className="camp-registration mobile-tab-reg">
                 {/* <section className="new-camp">
                     <h4>New 2022 Summer Camp Schedule and Registry!</h4>
                     <Link to="/summercamp"><button>Camp Schedule</button></Link>
                </section> */}
                {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '2%' }}>
                    <h4>A Member of</h4>
                    <img src={RTN} alt="RTN Logo"/>
                </div> */}
                {/* <h4>Class Registration</h4>
                <a href="https://ktmulkerin.wufoo.com/forms/m125nth1dccsjw/"><button>REGISTER HERE</button></a> */}
            </div>
            <Carousel/>
            <div className="lp-mobile-img">
                {/* <img src={PicAboutUs} alt="80th Year Celebration"/> */}
                <img src={PicCamps} alt="Dancing on Toes" />
                <h4>Offering classes throughout the Connecticut shoreline: Norwalk, Shelton, Milford, West Haven, Guilford, and Killingworth.</h4>
                <img src={PicFeis} alt="Dancing in Line" />
            </div>
            <div className="landing-section">
                <h4>Offering classes throughout the Connecticut shoreline: Norwalk, Shelton, Milford, West Haven, Guilford, and Killingworth.</h4>
            </div>
            <main>
                <div className="hvrbox">
                <img src={MulkerinLogo} alt="Mulkerin Logo" className="hvrbox-layer_bottom"/>
                    <Link to="/aboutus">
                        <div className="hvrbox-layer_top">
                            <div className="hvrbox-text">About Us</div>
                        </div>
                    </Link>
                </div>
                <div className="hvrbox">
                <img src={PicClasses} alt="Feet Around a Shamrock" className="hvrbox-layer_bottom"/>
                    <Link to="/classes">
                        <div className="hvrbox-layer_top">
                            <div className="hvrbox-text">Classes</div>
                        </div>
                    </Link>
                </div>
                {/* <div className="hvrbox">
                    <img src={PicFeis} alt="Dancing in Line" className="hvrbox-layer_bottom"/>
                    <Link to="/feis">
                        <div className="hvrbox-layer_top">
                            <div className="hvrbox-text">Feis</div>
                        </div>
                    </Link>
                </div> */}
                <div className="hvrbox">
                <img src={PicCamps} alt="Dancing on Toes" className="hvrbox-layer_bottom"/>
                    <Link to="/irelandteam">
                        <div className="hvrbox-layer_top">
                            <div className="hvrbox-text">Ireland Team</div>
                        </div>
                    </Link>
                </div>
            </main>
        </section>
    )
}


export default LandingPage;
