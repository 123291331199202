import React from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from
"mdbreact";
import C0 from "../images/PurpleDance.jpg"
import C1 from "../images/GreenGroup.jpg"
// import C2 from "../images/02grouppic.jpg"
import C2 from "../images/SpecialOlympics.png"
import C3 from "../images/03MulkerinFeis(2).png"
import C4 from "../images/04-PicStitch.jpg"
import C5 from "../images/05ribbondance.jpg"
import C6 from "../images/06Stretching.JPG"
import c7 from "../images/new1.png"
import c8 from "../images/new2.png"
import c9 from "../images/nov1.png"
import c10 from "../images/new4.jpg"
import c11 from "../images/nov2.JPG"
import c12 from "../images/new6.JPG"
import c13 from "../images/new7.jpg"
import c14 from "../images/new8.jpg"
import c15 from "../images/nov3.jpg"
import c16 from "../images/new10.jpg"
import c17 from "../images/new11.jpg"
import c18 from "../images/nov4.jpg"
import c19 from "../images/nov5.jpg"
import c20 from "../images/nov6.jpg"
import c21 from "../images/nov7.JPG"
import c22 from "../images/nov8.jpg"
// import Shamrock from '../images/Shamrock.jpg'
import BackgroundImg from '../images/ireland4.png';
// 
const Carousel = () => {


        return (
          <MDBContainer className="total-carousel">
            <MDBCarousel
              activeItem={1}
              length={19}
              showControls={true}
              showIndicators={true}
              className="z-depth-1"
            >
              <MDBCarouselInner>
                <MDBCarouselItem itemId="1">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={BackgroundImg}
                      alt="80 Years"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="2">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100 c6"
                      src={C0}
                      alt="2 Girls in Competition"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="3">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100 c6"
                      src={C1}
                      alt="New Leprachaun Show"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="4">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100 c3"
                      src={C2}
                      alt="Group"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="5">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={C5}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="6">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={C6}
                      alt="Dancers Stretching"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="7">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c7}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="8">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c8}
                      alt="Dancers Stretching"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="9">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c9}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                {/* <MDBCarouselItem itemId="10">
                   <MDBView>
                    <img
//                       style={{ padding: '4%', height:'600px' }} 
//                       className="d-block w-100"
//                       src={c10}
//                       alt="Dancers Stretching"
//                     />
//                   </MDBView>
//                 </MDBCarouselItem> */}
               <MDBCarouselItem itemId="10">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c11}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="11">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c12}
                      alt="Dancers Stretching"
                    />
                  </MDBView>
                </MDBCarouselItem>
                {/* <MDBCarouselItem itemId="13">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c13}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem> */}
                <MDBCarouselItem itemId="12">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c14}
                      alt="Dancers Stretching"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="13">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c15}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="14">
                  <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c16}
                      alt="Dancers Stretching"
                    />
                  </MDBView>
                </MDBCarouselItem>
                 <MDBCarouselItem itemId="15">
                 <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c18}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                 <MDBCarouselItem itemId="16">
                 <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c19}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                 <MDBCarouselItem itemId="17">
                 <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c20}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                 <MDBCarouselItem itemId="18">
                 <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c21}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
                 <MDBCarouselItem itemId="19">
                 <MDBView>
                    <img
                      style={{ padding: '4%', height:'600px' }} 
                      className="d-block w-100"
                      src={c22}
                      alt="Ribbon Dance"
                    />
                  </MDBView>
                </MDBCarouselItem>
              </MDBCarouselInner>
            </MDBCarousel>
          </MDBContainer>
        );
      }

export default Carousel;
