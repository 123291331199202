import React from 'react'
import Elizabeth from '../../images/MissElizabeth.png'

import TDC from '../../images/TheDanceCorner.png'


export default function MissElizabeth() {
    return (
        <div className="liz-container">
            <section>
                <h2>Meet Miss Elizabeth</h2>
            </section>
            <div className="liz-img tab-mobile">
                <img src={Elizabeth} alt="Miss Elizabeth"/>
                <h3>Elizabeth Florentine</h3>
            </div>
            <main className="liz-main">
                <div className="liz-img main-img">
                    <img src={Elizabeth} alt="Miss Elizabeth"/>
                    <h3>Elizabeth Florentine</h3>
                </div>
                <section>
                    <h3>Lead teacher at The Dance Corner</h3>
                    <p>Elizabeth began Irish dancing at 7 years old with the Mulkerin school at The Dance Corner in Killingworth and quickly fell in love. By her third year, she was helping in the beginner classes, getting as much practice in as she could.  Elizabeth earned the honor of traveling with the Emerald Dancers in 2011 on their two-week long trip to Ireland to perform. Throughout her dance career, she has won many medals and trophies for both solo and Ceili dancing. She is also a Registered Teacher’s Assistant (RTA) and is hoping to one day soon get her TCRG.</p>
                    {/* <br/> */}
                    <p>Elizabeth has never stopped Irish dancing. Throughout college she performed with Sacred Heart University’s Claddagh Dancers, competing at nearby intercollegiate competitions. Elizabeth also continues to perform and run St. Patrick’s Day shows in her area and loves to dance at any chance she has.</p>
                    {/* <br/> */}
                    <p>Besides Irish dancing, Elizabeth studied ballet, tap, jazz, lyrical, and pointe at The Dance Corner since she was 4 years  old. She competed in multiple styles of dance with their competition team and is now back as an instructor. Elizabeth was also a member of the SHU Dance Company studying jazz, tap, and lyrical. Outside of dance, Elizabeth is a Licensed Social Worker and loves working with young kids.</p>
                    <div>
                        <a href="https://thedancecorner.com/"><img src={TDC} alt="The Dance Corner Logo" className="tdc-logo"/></a>
                    </div>
                </section>
            </main>
        </div>
    )
}
