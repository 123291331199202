import React from 'react'
import HerselfPic from '../../images/Herself.png'

export default function Herself() {


    return (
        <div>
            {/* <div className="aboutus-title-div"><h3>About Us</h3></div> */}
            <div className="herself-container">
                <h2>Herself</h2>
                <div className="herself-tab-mobile">
                    <img alt="Mulkerin founder Kathleen Mulkerin" src={HerselfPic}/>
                    <h3>Kathleen Mulkerin</h3>
                </div>
                <section className="herself-combine">
                    <div className="herself-img">
                        <img alt="Mulkerin founder Kathleen Mulkerin" src={HerselfPic}/>
                        <h3>Kathleen Mulkerin</h3>
                    </div>
                    <main className="herself-main">
                        <p>Failte,</p>
                        <p>Have you ever asked yourself the following questions: Can I really learn to step dance? Why does Riverdance make my feet tap for joy while sitting? Why does Irish music make me smile and sing along? Kathleen Mulkerin never had to ask herself these questions.</p>
                        <p>Growing up in Stamford, Connecticut, she learned Irish dance and music with her siblings Michael, Martin, Beatrice and John at their father’s knee, Jack Mulkerin. Her father’s band the “Emerald Boys” taught them well.</p>
                        <p>This began a lifelong passion for dancing in Kathleen. A passion she passed along to her children, grandchildren and students.</p>
                        <p>Kathleen’s dancers have been featured in concert with many well-known Irish performers such as: Tommy Makem, The Clancy Brothers, Carmel Quinn, The Wolftones, and Black 47 to name a few. My mother was known worldwide for some of her choreographic work such as: Kennedy’s Farewell, the Green Barrette, the Galway Races and the Celtic Cross. She also choreographed dance routines for local theater productions at Sacred Heart University and other venues in the area.</p>
                        <p>My mother always believed that teaching the love of Irish dance and culture to her students was more important than the medals or trophies she and her dancers had won.</p>
                        <p>My mother’s many awards for dancing and choreography include:</p>
                            <ul>
                                <li>The Irish Culture Award: Stamford, CT</li>
                                <li>The Irish Appreciation Award: New Haven St. Patrick’s Day parade committee</li>
                                <li>Inducted into the North American Feis Commission Cultural Hall of Fame </li>
                            </ul>
                        <p>My mother’s school has been existence for 80 years. Although we have lost her, I will be following her example “keeping up the tradition” of teaching a love for our common Irish heritage and Celtic spirit of the dance.</p>
                        <p>Slainte,</p>
                        <p>Kathy Carew</p>
                    </main>
                </section>
            </div>
            {/* <div className="old-pic">
                <img alt="Picture of young Kathleen Mulkerin" src={HistoryPic}/>
            </div> */}
        </div>
    )
}
